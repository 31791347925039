.App {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  background-color: #0d1117;
  flex-grow: 1;
  
}

.posterCointainer{
  display: flex;
  justify-content: center;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('../public/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Grotesk Medium';
  src: url('../public/Grotesk Medium.otf');
  font-weight: normal;
  font-style: normal;
}


